<template>
  <nav class="navbar navbar-expand-lg bg-dark navbar-light bg-light">
    <a class="navbar-brand" href="index.html">
      <img class="nav-logo" src="../../public/icon/a44.png" />
      <!-- <img class="nav-split"
				src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/e16bf8c5-c4f8-4dc6-a726-66a254ed3faa.png" />
			<span class="nav-title">{{$t("homeSumaryInfo")}}</span> -->
    </a>
    <button
      @click.stop="showHideNavbar"
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse center-link"
      :class="{ show: showHideNavbarType }"
      id="navbarNavDropdown"
    >
      <ul class="navbar-nav">
        <li
          class="nav-item"
          @click="toLink(item, index)"
          :class="{ active: navIndex == index }"
          :key="index"
          v-for="(item, index) in navLinkList"
        >
          <a class="nav-link" href="#">{{ item.title }}</a>
        </li>
        <li
          class="nav-item hot_item"
          @click="toLink({ title: '美家搭配', link: '/tool', type: 1 }, '5')"
          :class="{ active: navIndex == '5' }"
        >
          <a class="nav-link" href="#">美家搭配</a>
          <div class="hot_img">
            <img src="store_img/joinHot.gif" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <a
      class="navbar-brand"
      @click="toLinkDump(item)"
      v-for="(item, index) in iconList"
      :key="index"
    >
      <img class="icon" :src="item.icon" />
    </a>
    <a class="navbar-brand head_photo_cont">
          <!-- @click="toLinkUserInfo()" -->
      <img
    
        @mouseenter="linkEnter"
        v-if="userInfo"
        class="avatar"
        :src="userInfo.head_photo ? userInfo.head_photo : headphoto"
      />
      <span
        v-else
        class="login_registered"
        @click="toLinkDump({ type: '', link: '#/login' })"
        >登录 | 注册</span
      >
      <!-- <img
        @click="toLinkUserInfo()"
        @mouseenter="linkEnter"
        v-else
        class="avatar"
        src="../../public/icon/headphoto.jpg"
      /> -->
      <div
        class="head_photo"
        @mouseenter="linkEnter"
        @mouseleave="linkLeave"
        v-show="tabInfo"
      >
        <div class="head_top">
          <img
            v-if="userInfo.head_photo"
            class="head_top_img"
            :src="userInfo.head_photo"
          />
          <img v-else class="head_top_img" :src="headphoto" />
          <div class="head_top_detail">
            <span class="name">{{ userInfo ? userInfo.name : "未登录" }}</span>
            <span class="cardId"
              >ID:{{ userInfo ? userInfo.phone : "123456" }}</span
            >
          </div>
        </div>
        <div class="head_mid">
          <div
            class="head_mid_list"
            @click="toLinkDump(item)"
            v-for="(item, index) in tabIcon"
            :key="index"
          >
            <img :src="item.icon" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="head_bot" @click="logout()">退出</div>
      </div>
    </a>
    <el-dialog title="价格开关验证密码" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="请输入密码：" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmPassword" 
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="价格开关" :visible.sync="dialogFormSwitch">
      <el-form :model="formSwitch">
          <span>价格开关：</span>
          <el-switch
          v-model="formSwitch.value"  
          active-color="#f39f3d"
          inactive-color="#f2f2f2">
        </el-switch>        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormSwitch = false">取 消</el-button>
        <el-button type="primary" @click="confirmSwitch"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </nav>
</template>
<script>
import dump from "../util/dump.js";
export default {
  props: ["navSelIndex"],
  data() {
    return {
      dialogFormVisible: false,
      dialogFormSwitch: false,
      formLabelWidth: '100px',
      form:{
        password:''
      },
      formSwitch:{
        value:true
      },
      userInfo: "",
      headphoto: require("../../public/icon/headphoto.jpg"),
      tabInfo: false,
      tabIcon: [
        {
          icon: require("../../public/icon/a5.png"),
          name: "个人中心",
          type: 2,
          link: "#/usercenter/personalCenter",
        },
        {
          icon: require("../../public/icon/a23.png"),
          name: "账户设置",
          type: 2,
          link: "#",
        },
        {
          icon: require("../../public/icon/a34.png"),
          name: "订单管理",
          type: 2,
          link: "#/my_order.html",
        },
        {
          icon: require("../../public/icon/a21.png"),
          name: "我的收藏",
          type: 2,
          link: "#/usercenter/collectionRecord",
        },
        {
          icon: require("../../public/icon/a11.png"),
          name: "浏览记录",
          type: 2,
          link: "#",
        },
        {
          icon: require("../../public/icon/a22.png"),
          name: "消息中心",
          type: 2,
          link: "#",
        },
      ],
      iconList: [
        {
          icon: require("../../public/icon/a10.png"),
          type: 99,
          link: "#",
        },
        {
          icon: require("../../public/icon/a8.png"),
          type: 2,
          link: "#/usercenter/shopCart",
        },
      ],
      navLinkList: [
        {
          title: "首页",
          link: "/",
          type: 1,
        },
        // {
        //   title: "楼盘方案",
        //   link: "/buildingPlan",
        //   type: 1,
        // },
        {
          title: "美家套餐",
          link: "/packageList",
          type: 1,
        },
        {
          title: "商城",
          link: "/productList",
          type: 1,
        },
        {
          title: "720°VR设计",
          link: "/threeDimensional",
          type: 1,
        },
      ],
      navIndex: this.navSelIndex ? this.navSelIndex : 0,
      showHideNavbarType: false,
    };
  },
  mounted() {
    this.getUserInfoData();
  },
  methods: {
    confirmPassword(){
      const that = this;
      let phone = localStorage.getItem("phone")
      // let phone = '18174094702'
      that.$api.checkboxPassword({phone: phone,password: this.form.password}).then((res) => {
        if(res.status == 100){
          that.$message({
            message: '密码验证成功！',
            type: 'success'
          });
          console.log(res)
          that.dialogFormVisible = false
          that.dialogFormSwitch = true
          that.formSwitch.value = res.data == 0
        }
      })
    },
    confirmSwitch(){
      const that = this;
      that.$api.checkboxSet({priceChange: that.formSwitch.value == false ? '1':'0',}).then((res) => {
        if(res.status == 103){
          location.href = "#/login"
          return false;
        }
        if(res.status == 100){
          that.$message({
            message: '修改成功！',
            type: 'success'
          });
          localStorage.setItem("role_id", res.data);
          that.dialogFormSwitch = false
          that.$router.go(0)
        }
      })
    },
    logout() {
      localStorage.clear();
      this.tabInfo = false;
      location.href = "#/login";
    },
    // toLinkUserInfo(){
    //   this.tabInfo = !this.tabInfo
    // },
    linkEnter() {
      this.tabInfo = true;
    },
    linkLeave() {
      this.tabInfo = false;
    },
    getUserInfoData() {
      const that = this;
      console.log("12355");
      that.$api.getUserInfo({}).then((res) => {
        if (res.status == 103) {
          // that.$message({
          //   message: res.msg,
          //   type: 'warning'
          // });
          // location.href = "#/login"
        }
        if (res.status == 100) {
          that.userInfo = res.data;
          localStorage.setItem("headPhoto", res.data.head_photo);
        }
      });
    },
    showHideNavbar: function () {
      this.showHideNavbarType = !this.showHideNavbarType;
    },
    toLink: function (item, index) {
      if (index == this.navIndex) {
        return false;
      }
      console.log(index);
      console.log(this.navIndex)
      this.showHideNavbarType = false;
      this.navIndex = index;
      this.toLinkDump(item);
    },
    toLinkDump: function (item) {
      console.log(item)
      if (item.type == 99) {
        this.dialogFormVisible = !this.dialogFormVisible;
      } else {
        dump.link(item);
        // this.$router.push(item.lin)
      }

      // if (item.type == 1) {
      // 	this.$router.push({
      // 		path: item.link
      // 	});
      // } else {
      // 	window.location.href = item.link;
      // }
    },
  },
};
</script>

<style scoped="scoped">
@media (min-width: 992px) {
  .nav-link {
    margin: auto 1.5625rem;
  }
}
@media (min-width: 1100px) {
  .center-link {
    margin-left: 4.3625rem;
  }
  .nav-link {
    margin: auto 1.65625rem;
  }
  /deep/.el-dialog{
    width: 40% !important;
  }
}
@media (min-width: 1625px) {
  .nav-link {
    margin: auto 2.65625rem;
  }
  .center-link {
    margin-left: 16.3625rem;
  }
  /deep/.el-dialog{
    width: 25% !important;
  }
}
.hot_img {
  position: absolute;
  top: -0.4375rem;
  right: 1.375rem;
}
.hot_img img {
  width: 1.33rem;
  height: 0.83rem;
}
.hot_item {
  position: relative;
}
.head_photo_cont {
  position: relative;
}
.head_photo {
  position: absolute;
  top: 3.3125rem;
  left: -9.125rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  width: 13.33rem;
}
.head_top_img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.head_top {
  display: flex;
  background: #32353c;
  padding: 1rem;
}
.head_top_detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.89rem;
}
.head_top_detail .name {
  font-size: 0.81rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
}
.head_top_detail .cardId {
  font-size: 0.81rem;
  font-family: ArialMT;
  font-weight: 400;
  color: #ffffff;
}
.head_mid {
  background: #ffffff;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.56rem;
}
.head_mid_list {
  padding: 0.36rem 0.64rem;
  cursor: pointer;
}
.head_mid_list img {
  width: 1.17rem;
  height: 1.17rem;
  margin-right: 0.28rem;
}
.head_mid_list span {
  font-size: 0.81rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #3e3a39;
}
.head_bot {
  background: #f3f3f3;
  font-size: 0.81rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #727171;
  line-height: 2rem;
  cursor: pointer;
}
.navbar {
  padding: 0.28125rem 1rem !important;
}
.bg-dark {
  background-color: #000000 !important;
}

.nav-split {
  height: 2.1875rem;
  margin: auto 0.75rem;
}

.nav-logo {
  width: 11.4375rem;
  height: 2.5625rem;
}

.nav-title {
  height: 0.75rem;
  font-size: 0.8125rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
}

.nav-link {
  display: unset !important;
  padding: unset !important;
  text-decoration: none !important;
  transition: unset !important;
  font-size: 0.9375rem !important;
  font-family: Source Han Sans SC !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  padding: 1.1rem 0 !important;
}

.active {
  /*  */
  color: #f39f3d !important;
}
@media (min-width: 1200px) {
  .active .nav-link {
    padding: 1.4375rem 0;
    color: #f39f3d !important;
    border-bottom: 2px solid #f39f3d;
  }
}
@media (max-width: 1200px) {
  .active .nav-link {
    padding: 1.4375rem 0;
    color: #f39f3d !important;
  }
}

.nav-link {
  line-height: 1.875rem;
  padding: 1.4375rem 0;
}

.navbar-light .navbar-toggler {
  background-color: #ffffff;
}

.icon {
  /* height: 1.75rem; */
  cursor: pointer;
}

.avatar {
  width: 2.3125rem;
  height: 2.3125rem;
  border-radius: 50%;
  cursor: pointer;
}
.login_registered {
  color: #ffffff;
  font-size: 0.75rem;
  cursor: pointer;
}

@media (min-width: 375px) {
  .bg-dark {
    max-width: 100% !important;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
}

@media (min-width: 1600px) {
  .bg-dark {
    max-width: 100% !important;
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .active {
  }
}
</style>
